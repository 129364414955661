function useRequiredField() {

  const show = ref(false)

  function openRequired() {
    show.value = true
  }
  function closeRequired() {
    show.value = false
  }

  return {
    show,
    openRequired,
    closeRequired
  }

}


export default createGlobalState(useRequiredField)